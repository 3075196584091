<template>
  <vue-page-transition name="fade">
    <LoaderAnimation v-if="loading" />
    <form
      v-else
      name="play"
      class=""
      :key="action"
      @submit.prevent="uploadPlay"
    >
      <fieldset :disabled="uploadingPlay">
        <div class="images">
          <div class="cover image">
            <img
              v-if="play.backdrop_path || preview.cover"
              :src="preview.cover ? preview.cover : play.backdrop_path"
              alt=""
            />
            <label class="custom-file-upload">
              <input
                id="coverImage"
                type="file"
                accept="image/*"
                @change="addFiles($event, 'cover')"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 24 24"
              >
                <path
                  d="M14,15.5V8h3l-5-5L7,8h3v7.5c0,0.3,0.2,0.5,0.5,0.5h3C13.8,16,14,15.8,14,15.5z"
                  opacity=".3"
                />
                <path
                  d="M2 20H22V22H2zM13.5 17h-3C9.7 17 9 16.3 9 15.5V9H4.6L12 1.6 19.4 9H15v6.5C15 16.3 14.3 17 13.5 17zM11 15h2V7h1.6L12 4.4 9.4 7H11V15z"
                />
              </svg>
              <span>Upload Cover Image</span>
            </label>
            <button
              v-if="preview.cover"
              class="btn"
              @click="removeImage('cover')"
            >
              Remove Image
            </button>

            <form
              v-if="dragAndDropCapable"
              @dragover.stop.prevent
              @dragenter="toggleClass"
              @dragleave="toggleClass"
              @drop.stop.prevent="addFiles($event, 'cover')"
              ref="coverImage"
            ></form>
          </div>

          <div class="poster image">
            <img
              v-if="play.poster_path || preview.poster"
              :src="preview.poster ? preview.poster : play.poster_path"
              alt=""
            />
            <label class="custom-file-upload">
              <input
                id="posterImage"
                type="file"
                accept="image/*"
                @change="addFiles($event, 'poster')"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 24 24"
              >
                <path
                  d="M14,15.5V8h3l-5-5L7,8h3v7.5c0,0.3,0.2,0.5,0.5,0.5h3C13.8,16,14,15.8,14,15.5z"
                  opacity=".3"
                />
                <path
                  d="M2 20H22V22H2zM13.5 17h-3C9.7 17 9 16.3 9 15.5V9H4.6L12 1.6 19.4 9H15v6.5C15 16.3 14.3 17 13.5 17zM11 15h2V7h1.6L12 4.4 9.4 7H11V15z"
                />
              </svg>
              <span>Upload Cover Image</span>
            </label>
            <button
              v-if="preview.poster"
              class="btn"
              @click="removeImage('poster')"
            >
              Remove Image
            </button>

            <form
              v-if="dragAndDropCapable"
              @dragover.stop.prevent
              @dragenter="toggleClass"
              @dragleave="toggleClass"
              @drop.stop.prevent="addFiles($event, 'poster')"
              ref="coverImage"
            ></form>
          </div>
        </div>

        <div class="details">
          <div class="input-fields">
            <div class="input-group">
              <input
                type="text"
                placeholder=" "
                id="title"
                required
                autocomplete="text"
                v-model="play.name"
              />
              <label for="title">Play Title</label>
            </div>
            <div class="two-column">
              <!-- <div class="genre-selector">
                <label for="genres" class="genres">
                  <template v-if="play.genres.length > 0">
                    <div
                      v-for="(genre, index) in play.genres"
                      :key="genre"
                      class="genre"
                      @click="removeGenre(index)"
                    >
                      {{ genre }}
                    </div>
                  </template>
                  <label v-else>Play Genres</label>
                </label>
                <div class="input-group">
                  <select id="genres" @change="addGenre" required>
                    <option v-for="genre in genres" :key="genre" :value="genre">
                      {{ genre }}
                    </option>
                  </select>
                </div>
              </div> -->
              <AdminsGenres
                v-if="genres"
                :genres="genres"
                v-model="play.genres"
              />

              <div class="input-group">
                <select
                  placeholder=" "
                  v-model="play.media_type"
                  id="media-type"
                  required
                >
                  <option value="TV">TV</option>
                  <option value="Film">Film</option>
                </select>
                <label for="media-type">Made For</label>
              </div>
            </div>
            <div class="input-group">
              <textarea
                placeholder=" "
                id="decription"
                required
                autocomplete="text"
                v-model="play.overview"
              />
              <label for="decription">Play Description</label>
            </div>
            <label class="pdf" @change="addPDF">
              <input type="file" accept=".pdf" :required="!id" />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 24 24"
              >
                <path
                  d="M12.5,23c-3,0-5.5-2.5-5.5-5.5V5c0-2.2,1.8-4,4-4c2.2,0,4,1.8,4,4v10.5c0,1.4-1.1,2.5-2.5,2.5c-1.4,0-2.5-1.1-2.5-2.5V6h2 v9.5c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5V5c0-1.1-0.9-2-2-2S9,3.9,9,5v12.5c0,1.9,1.6,3.5,3.5,3.5s3.5-1.6,3.5-3.5V6h2v11.5 C18,20.5,15.5,23,12.5,23z"
                />
              </svg>
              <p>{{ PDFname }}</p>
            </label>
            <p class="error">{{ pdfError }}</p>
            <button type="submit" :class="{ loading: uploadingPlay }">
              <template v-if="!uploadingPlay">Upload Play</template>
            </button>
          </div>

          <vue-pdf-app
            v-if="pdf.tempFile || play.pdf"
            v-show="!isMobile"
            :config="config"
            :pdf="pdf.tempPath || play.pdf"
            @open="checkPDFLength"
          ></vue-pdf-app>
        </div>
      </fieldset>
    </form>
  </vue-page-transition>
</template>

<script>
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";
import { scriptCollections } from "@/firebase-config";
import { mapGetters, mapActions } from "vuex";
import { LoaderAnimation, AdminsGenres } from "@/components";

export default {
  name: "UploadPlay",
  props: {
    id: [String, Number],
    action: String,
    playToUpdate: Object,
  },
  components: {
    VuePdfApp,
    LoaderAnimation,
    AdminsGenres,
  },
  data() {
    return {
      play: {
        name: null,
        id: null,
        overview: null,
        backdrop_path: null,
        poster_path: null,
        pdf: null,
        media_type: "TV",
        genres: [],
        owner: null,
        views: 0,
      },
      preview: {
        cover: null,
        poster: null,
      },
      files: {
        cover: null,
        poster: null,
        pdf: null,
      },
      pdfError: null,
      pdf: {
        tempPath: null,
        tempFile: null,
      },
      config: {
        sidebar: false,
        secondaryToolbar: false,
        toolbar: false,
      },
      loading: true,
      uploadingPlay: false,
    };
  },
  computed: {
    dragAndDropCapable() {
      // Create a test element to see if certain events are present that let us do drag and drop.
      const div = document.createElement("div");
      // Check to see if the `draggable` event is in the element or the `ondragstart` and `ondrop` events are in the element.
      return (
        ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
        "FormData" in window &&
        "FileReader" in window
      );
    },
    isMobile() {
      return window.innerWidth < 750;
    },
    PDFname() {
      return (
        this.files?.pdf?.name ||
        `Upload Script PDF (Maximum of ${this.settings.pdfPageLimit} pages)`
      );
    },
    ...mapGetters(["settings"]),
    genres() {
      return this.$store.getters.settings.genres.filter(
        (x) => !this.play.genres.includes(x)
      );
    },
  },
  methods: {
    ...mapActions(["uploadFile"]),
    async getPlay() {
      this.loading = true;
      try {
        const doc = await scriptCollections.doc(this.id).get();
        if (doc.exists) {
          this.play = doc.data();
          this.play.id = doc.id;
        }
      } catch (err) {
        throw new Error(err);
      } finally {
        this.loading = false;
      }
    },
    addFiles(e, path) {
      this.toggleClass(e);

      if (e.type === "change") {
        this.files[path] = e.target.files[0];
      } else {
        this.files[path] = e.dataTransfer.files[0];
      }
      this.getImagePreviews(path);
    },
    getImagePreviews(path) {
      if (/\.(jpe?g|png|gif|webp)$/i.test(this.files[path].name)) {
        let reader = new FileReader();

        reader.addEventListener("load", () => {
          this.preview[path] = reader.result;
        });

        // Read the data for the file in through the reader. When it has been loaded, we listen to the event propagated and set the image src to what was loaded from the reader.

        reader.readAsDataURL(this.files[path]);
      } else {
        // We do the next tick so the reference is bound and we can access it.
        throw new Error("Not a valid image type");

        // this.$nextTick(() => {
        //   this.preview[path] = "/images/file.png";
        // });
      }
    },
    toggleClass(event) {
      event.target.parentElement.classList.toggle("active");
    },
    removeImage(path) {
      this.files[path] = "";
      this.preview[path] = null;
    },
    addPDF(e) {
      this.pdfError = null;

      let file;
      if (e.type === "change") {
        file = e.target.files[0];
      } else {
        file = e.dataTransfer.files[0];
      }

      var tempPath = URL.createObjectURL(file);
      this.$set(this.pdf, "tempPath", tempPath);
      this.$set(this.pdf, "tempFile", file);
    },
    async checkPDFLength(pdfApp) {
      if (pdfApp.pagesCount <= this.settings.pdfPageLimit) {
        this.$set(this.files, "pdf", this.pdf.tempFile);
      } else {
        this.pdf = {
          tempPath: null,
          tempFile: null,
        };
        this.$set(this.files, "pdf", null);
        this.pdfError = `Please choose a PDF documment wih ${this.settings.pdfPageLimit} or less pages`;
      }
    },
    async uploadPlay() {
      this.pdfError = null;
      this.uploadingPlay = true;
      const bucket = this.play.bucket || this.play.name;

      if (this.play.genres.length < 0) return;

      try {
        if (this.files.poster) {
          this.play.poster_path = await this.uploadFile({
            path: bucket,
            file: this.files.poster,
            name: this.files.poster.name,
          });
        }
        if (this.files.cover) {
          this.play.backdrop_path = await this.uploadFile({
            path: bucket,
            file: this.files.cover,
            fileName: this.files.cover.name,
          });
        }
        if (this.files.pdf) {
          this.play.pdf = await this.uploadFile({
            path: bucket,
            file: this.files.pdf,
            fileName: this.files.pdf.name,
          });
        }

        const scriptToUpload = this.play;
        if (!this.play.bucket) scriptToUpload.bucket = bucket;
        delete scriptToUpload.id;

        if (this.id)
          await scriptCollections.doc(this.id).update(scriptToUpload);
        else await scriptCollections.add(scriptToUpload);

        alert("Play successfully uploaded");
        this.resetPlay();
      } catch (err) {
        this.pdfError = err.message;
      } finally {
        this.uploadingPlay = false;
      }
    },
    resetPlay() {
      const data = {
        play: {
          name: null,
          id: null,
          overview: null,
          backdrop_path: null,
          poster_path: null,
          pdf: null,
          media_type: "TV",
          genres: [],
          owner: null,
          views: 0,
        },
        preview: {
          cover: null,
          poster: null,
        },
        files: {
          cover: null,
          poster: null,
          pdf: null,
        },
        pdfError: null,
        pdf: {
          tempPath: null,
          tempFile: null,
        },
      };

      for (const key in data) {
        this.$data[key] = data[key];
      }
    },
  },
  async beforeMount() {
    if (this.id) this.getPlay();
    else if (this.playToUpdate) this.play = this.playToUpdate;
    else this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.images {
  display: flex;
  flex-wrap: nowrap;
  height: 300px;

  .image {
    overflow: hidden;
    background: #202125;
    border-radius: 10px;
    height: 100%;
    width: 100%;
    position: relative;
    transition: 0.3s all ease;
    display: flex;
    place-items: center;
    justify-content: center;
    flex-direction: column;

    img {
      object-fit: cover;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      opacity: 0.7;
    }

    &.poster {
      margin-left: 10px;
      width: 300px;
    }

    .custom-file-upload {
      z-index: 1;
      cursor: pointer;
      font-size: 1.3rem;
      display: block;
      text-align: center;

      input[type="file"] {
        display: none;
      }

      span {
        display: block;
      }

      svg {
        fill: $dark-grey;
        height: 70px;
      }

      &:hover {
        color: turquoise;

        svg {
          fill: turquoise;
        }
      }
    }

    button {
      padding: 10px;
      border-radius: 100px;
      margin-top: 10px;
      font-size: 1rem;
      background: $red;
      color: white;
      z-index: 1;
    }

    form {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }

    &.active {
      transform: scale(0.9);
      border: 1px solid turquoise;

      label {
        color: turquoise;

        svg {
          fill: turquoise;
        }
      }

      img {
        opacity: 0;
      }
    }
  }
}

.details {
  margin-top: 10px;
  display: flex;

  #vuePdfApp {
    width: 300px;
    transition: 0.2s width ease;
    margin-left: 10px;
    height: 450px !important;
    border-radius: 10px;

    /deep/ .page {
      width: 100% !important;
      height: 450px !important;
      border: none;
    }
  }

  .input-fields {
    flex-grow: 1;
    /* max-width: 573px; */
  }

  .pdf {
    background: black;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    cursor: pointer;

    svg {
      height: 15px;
      margin-right: 10px;
      fill: $dark-grey;
    }

    input[type="file"] {
      display: none;
    }
  }

  .input-group {
    input,
    textarea {
      background: #202125;
    }
  }

  textarea {
    height: calc(300px - 15px - 50px);
    line-height: 1.6;
    font-size: 1.6rem;
    padding-top: 25px;
    resize: none;
  }

  button {
    display: block;
    padding: 16px;
    // border-radius: 4px;
    border-radius: 100px;
    font-size: 1.6rem;
    font-weight: 400;
    margin: 24px 0 10px;
    width: 100%;
    background: $red;
    color: white;
    transition: 0.2s background ease;

    &:hover {
      background: $dark-red;
    }
  }
}

.error {
  color: $red;
  font-size: 1.3rem;
  margin-top: 5px;
  text-align: center;
}

.two-column {
  display: grid;
  grid-column-gap: 15px;
  grid-template-columns: 1fr 1fr;

  .input-group {
    select {
      width: 100%;
      padding: 16px;
      background: #202125;
      outline: none;
      border: none;
      height: 100%;
      border-radius: 10px;
      color: white;
      cursor: pointer;
    }

    label[for="media-type"] {
      display: none;
    }
  }
}

fieldset {
  border: none;
}

/deep/ .loading {
  height: 70vh;
}
</style>
